import React, { useState } from "react";
import { Button, Form, Modal } from "antd";
import { BoxConfig, extarPropsType } from "../getInput";
import { BasicInput } from "./BasicInput";
import { isFunction } from "lodash";
import { useTranslation } from "react-i18next";
import HighlightText from "../../HighlightText";

export const BasicVerificationCode = (
  item: BoxConfig,
  { inputDefaultStyle }: extarPropsType
) => {
  const { btnContent, ...inputOptions } = item?.inputOptions || {};

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <div className=" flex gap-5">
        <Form.Item name="captcha" noStyle>
          {BasicInput({ ...item, inputOptions }, { inputDefaultStyle })}
        </Form.Item>
        <Button
          className="border border-solid border-dividingLine"
          style={{
            color: "var(--color-primary)",
            ...btnContent?.btnStyle,
          }}
          loading={btnContent?.btnLoading || false}
          disabled={btnContent?.btnDisabled}
          onClick={() => {
            if (isFunction(btnContent?.btnClick)) {
              btnContent?.btnClick();
            }
          }}
        >
          {btnContent?.btnText || "Get Captcha"}
        </Button>
      </div>

      <HighlightText
        text={t("emailCodeHelp")}
        style={{
          marginTop: 12,
          color: "var(--color-textAssist)",
          fontSize: 12,
        }}
        highlightTextStyle={{
          color: "var(--color-blueColor)",
        }}
        clickableIndexs={[0]}
        onClickIndex={() => {
          setOpen(true);
        }}
      />

      <Modal
        centered={true}
        open={open}
        title={t("commonProblem")}
        onClose={() => {
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
        footer={null}
        width={600}
      >
        <div className="overflow-auto max-h-[600px]">
          <div className="text-cMainTxt text-[16px] font-medium">
            {t("emailCodeHelpTitle")}
          </div>
          <div className="text-cMinorTxt text-[14px] whitespace-pre-line mt-[16px]">
            {t("emailCodeHelpDesc")}
          </div>
        </div>
      </Modal>
    </div>
  );
};

import clsx from "clsx";
import React from "react";
interface StatusComponentType {
  value: string | React.ReactNode;
  flag?: "success" | "info" | "warning" | "inProgress" | "error";
  children?: React.ReactNode;
  className?: string;
}

const StatusComponent = (props: StatusComponentType) => {
  const { flag, value, children, className } = props;

  return (
    <span
      className={clsx(
        ` inline-block px-[10px] py-[3px] rounded-[4px] leading-[20px] text-[14px] ${className}`,
        flag === "success"
          ? "bg-bgSuccess text-cSuccess"
          : flag === "info"
            ? "bg-bgGray text-cGray"
            : flag === "warning"
              ? "bg-bgWarn text-cWarn"
              : flag === "inProgress"
                ? "bg-bgBlue text-cBlue"
                : flag === "error"
                  ? "bg-bgError text-cError"
                  : "bg-modulesBackground text-cGray"
      )}
    >
      {value}
      {children}
    </span>
  );
};

export default StatusComponent;

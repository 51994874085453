import React from "react";
import { Transfer, Tree, Checkbox } from "antd";
import type { GetProp, TransferProps, TreeDataNode } from "antd";
import { mustArray } from "../../utils";
type TransferItem = GetProp<TransferProps, "dataSource">[number];
interface TreeTransferType {
  status?: TransferProps["status"];
  dataSource: TreeDataNode[];
  flatSource: [];
  targetKeys: TransferProps["targetKeys"];
  onChange: TransferProps["onChange"];
}
const isChecked = (selectedKeys: number[], eventKey: number) => {
  selectedKeys.includes(eventKey);
};

const generateTree = (
  treeNodes: TreeDataNode[] = [],
  checkedKeys: TreeTransferType["targetKeys"] = []
): TreeDataNode[] =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    disabled: checkedKeys.includes(props.key as string),
    children: generateTree(children, checkedKeys),
  }));
const TreeTransfer: React.FC<TreeTransferType> = ({
  dataSource,
  flatSource,
  targetKeys = [],
  ...restProps
}) => {
  const transferDataSource: TransferItem[] = [];
  const flatten = (list: TreeDataNode[] = []) => {
    list.forEach((item) => {
      transferDataSource.push(item as TransferItem);
      flatten(item.children);
    });
  };
  console.log(targetKeys, "targetKeys");
  flatten(dataSource);
  const searchChildrenId = (node: any, arr: any[]) => {
    node.forEach((item: any) => {
      console.log(item, "32432432");
      arr.push(item.id);
      if (item.children) searchChildrenId(item.children, arr);
    });
  };
  const getChildrenId = (value: number, arr: any[]) => {
    const childrenId = transferDataSource.filter(
      (item: any) => item.parentId === value
    );
    if (childrenId) {
      childrenId.forEach((item) => {
        arr.push(item.id);
        if (item.children) {
          getChildrenId(item.id, arr);
        }
      });
    }
  };
  return (
    <Transfer
      {...restProps}
      // showSearch
      showSelectAll={false}
      targetKeys={targetKeys}
      dataSource={transferDataSource}
      className="tree-transfer"
      render={(item) => item.label}
      listStyle={{
        width: "100%",
        height: "300px",
        overflowY: "scroll",
      }}
    >
      {({ direction, onItemSelect, onItemSelectAll, selectedKeys }) => {
        if (direction === "left") {
          const checkedKeys = [...selectedKeys, ...targetKeys];
          return (
            <div style={{ padding: 4, height: 300 }}>
              <Tree
                blockNode
                checkable
                checkStrictly
                defaultExpandAll
                checkedKeys={checkedKeys}
                treeData={generateTree(dataSource, targetKeys)}
                onCheck={(_, { node: { key }, node }) => {
                  console.log(key);
                  console.log(node, "2312");
                  if (node.children) {
                    const arr = [key];
                    searchChildrenId(node.children, arr);
                    console.log(arr, "ewrewr");
                    onItemSelectAll(
                      arr,
                      selectedKeys.includes(key) ? false : true
                    );
                  } else {
                    onItemSelect(
                      key as string,
                      selectedKeys.includes(key) ? false : true
                    );
                  }
                }}
                onSelect={(_, { node: { key }, node }) => {
                  if (node.children) {
                    const arr = [key];
                    searchChildrenId(node.children, arr);
                    console.log(arr, "ewrewr");
                    onItemSelectAll(
                      arr,
                      selectedKeys.includes(key) ? false : true
                    );
                  } else {
                    onItemSelect(
                      key as string,
                      selectedKeys.includes(key) ? false : true
                    );
                  }
                }}
              />
            </div>
          );
        }
        if (direction === "right") {
          const checkedKeys = [...selectedKeys, ...targetKeys];
          console.log(flatSource, "flatSource");
          console.log(checkedKeys, "checkedKeys");
          console.log(transferDataSource, "transferDataSource");
          return (
            <div className="grid gap-2 pl-3">
              {mustArray(flatSource).map((ele: any) => {
                return checkedKeys.indexOf(ele.id) === -1 ||
                  checkedKeys.indexOf(ele.parentId) !== -1 ? null : (
                  <Checkbox
                    key={ele.id}
                    value={ele.id}
                    onChange={(val) => {
                      console.log(val);
                      const value = val.target.value;
                      const res: any[] = [];
                      getChildrenId(value, res);
                      console.log(res, "erewr");
                      // return;
                      // const childrenId = transferDataSource.filter(
                      //   (item: any) => item.parentId === value
                      // )[0];
                      // console.log(childrenId, "childrenId");
                      if (res.length >= 1) {
                        const arr = [value].concat(res);
                        console.log(arr, "ewrew");
                        onItemSelectAll(
                          arr,
                          selectedKeys.includes(value) ? false : true
                        );
                      } else {
                        onItemSelect(
                          value as string,
                          selectedKeys.includes(value) ? false : true
                        );
                      }
                    }}
                  >
                    {ele.label}
                  </Checkbox>
                );
              })}
            </div>
          );
        }
      }}
    </Transfer>
  );
};
export default TreeTransfer;

import { localGetString } from "@/utils";
import { CHANGE_MENU, CHANGE_LAYOUT, CHANGE_COLOR } from "z/config/constant";
// 1 2 3
const themeColor: string = `${localGetString(CHANGE_COLOR)}` || "1";
// 布局选择 left top mix
const navLayout: string = localGetString(CHANGE_LAYOUT) || "top";
// 初始化菜单展开或者收起
const menuOperation: boolean = (localGetString(CHANGE_MENU) as unknown as boolean) === true;

export { themeColor, navLayout, menuOperation };

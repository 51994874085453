import React, { useState } from "react";
import { ReactNode, useMemo } from "react";
import { Button, Form, FormInstance } from "antd";
import { InputType, getInput, inputDefaultStyleType } from "./getInput";
import { handleRemoveTrim, mustArray } from "../../utils";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
const defaultFormItems = [
  {
    name: "id",
    inputOptions: {
      placeholder: "Search Site Name or ID",
      // prefix: <SearchOutlined />,
    },
  },
  {
    // label: "日期",
    name: "Date",
    inputType: InputType.DataPicker,
    inputOptions: {
      placeholder: "请选择日期",
    },
  },
  {
    // label: "下拉选择",
    name: "select",
    inputType: InputType.Select,
    selectData: [
      {
        value: 1,
        label: "你是个小笨蛋",
      },
      {
        value: 2,
        label: "你是个大笨蛋",
      },
      {
        value: 3,
        label: "切克闹",
      },
    ],
    inputOptions: {
      placeholder: "请选择下拉数据",
    },
  },
];

const inputDefaultStyle: inputDefaultStyleType = {
  width: 250,
  height: 40,
};
interface formItemsType {
  id: string;
  label?: string;
  inputOptions?: any;
  selectData?: selectDataType[];
  cascaderData?: cascaderDataType[];
  inputType?: string;
  option?: any;
}
interface queryType {
  key?: string;
  name?: string;
  onSearch: (newValue?: any) => void;
  onReset?: () => void;
  extraOnResetFe?: () => void;
  resetFields?: (newValue?: boolean) => void;
  extarContent?: ReactNode;
  searchTitle?: string;
  resetTitle?: string;
  px?: string;
  form: FormInstance<any>;
  formItems: formItemsType[];
  boxClassName?: string;
  extarContentStyle?: string;
  notBetween?: boolean;
}

export interface selectDataType {
  value: string | number;
  label: string | ReactNode;
}
interface cascaderDataType {
  value: string;
  label: string;
  children?: cascaderDataType[];
}

const QueryBar = (props: queryType) => {
  const [searchBtnLoading, setSearchBtnLoading] = useState(false);
  const { t } = useTranslation();
  const {
    onSearch,
    onReset,
    name = "queryForm",
    form,
    formItems,
    searchTitle = t("comSearch"),
    resetTitle = t("comReset"),
    extarContent,
    extarContentStyle = "",
    px = "px-[1.5rem]",
    boxClassName = "",
    notBetween = false,
  } = props;

  // 查询
  const onFinish = async () => {
    setSearchBtnLoading(true);
    const values = form.getFieldsValue() || {};
    console.log(values, "ew32432");
    // 去掉字符串的前后空格
    handleRemoveTrim(values);
    // Object.entries(values).forEach(([key, value]) => {
    //   if (typeof value === "string") {
    //     values[key] = value.trim();
    //   }
    //   if (value === "") {
    //     values[key] = undefined;
    //   }
    // });
    await onSearch(values);
    setSearchBtnLoading(false);
  };

  // 重置
  const onResetFe = () => {
    form.resetFields();
    if (onReset) {
      onReset?.();
    } else {
      const values = { ...form.getFieldsValue() };
      onSearch(values || {});
    }
  };

  // useEffect(() => {
  //   if (name) {
  //     console.log("name", name);
  //     form.resetFields();
  //   }
  // }, [name]);
  const currentFormItems = useMemo(() => {
    return formItems || defaultFormItems;
  }, [formItems]);

  const getFields = () => {
    return (
      <>
        {mustArray(currentFormItems).map((item: formItemsType) => {
          return (
            <Form.Item
              tooltip={"222"}
              key={item.id}
              label={item?.label}
              name={item.id}
              className="!mb-[0rem] flex items-center"
              {...(item?.option || {})}
            >
              {getInput(item, { inputDefaultStyle, name: item.id })}
            </Form.Item>
          );
        })}
        <Form.Item className=" !mb-[0rem] flex items-center">
          <Button
            // hidden={true} //为了回车也能支持查询
            className="mr-[0.625rem]"
            type="primary"
            size="large"
            htmlType="submit"
            loading={searchBtnLoading}
          >
            {searchTitle}
          </Button>
          <Button onClick={onResetFe} size="large">
            {resetTitle}
          </Button>
        </Form.Item>
      </>
    );
  };

  return (
    <div
      className={clsx(
        `flex pb-6 ${notBetween ? "justify-start" : "justify-between"} ${px} ${boxClassName}`
      )}
    >
      <Form
        form={form}
        name={name}
        className="flex flex-wrap overflow-hidden gap-[0.75rem]"
        onFinish={onFinish}
      >
        {getFields()}
      </Form>
      {extarContent && (
        <div className={`ml-2 ${extarContentStyle}`}>{extarContent}</div>
      )}
    </div>
  );
};

export default QueryBar;

import { localGetString } from "@/utils";
import { judgeLanguage, langList } from "common/utils";
import { atom } from "jotai";
import { CHANGE_LANG_KEY } from "z/config/constant";
import { menuOperation, navLayout, themeColor } from "z/config/setting";

// langKey.startsWith("en")
function getInitChooseLang() {
  const languageKey = localGetString(CHANGE_LANG_KEY);
  if (languageKey) {
    return languageKey || "";
  } else if (
    langList.findIndex((item) => {
      const text = `${item.val}`.split("-");
      return navigator?.language.includes(text[0]);
    }) > -1 ||
    navigator?.language.includes("zh")
  ) {
    // 这里特殊在，系统预制有中文，但是浏览器下拉选又没显示中文， 所以在条件里加了判断如果浏览器默认语言有中文就还是显示跟随语言， 不然下拉选里他查不到中文，就跑去默认成英文了
    return "sysWebLanguage" || "";
  } else {
    return "en-US";
  }
}

const switchLang = atom<string>(getInitChooseLang());
const collapseMenu = atom<boolean>(menuOperation || false);
// 菜单布局设置
const collapseLayout = atom<string>(navLayout);

const chooseThemeColor = atom<string>(themeColor);
const routerList = atom<any>([]);
export { collapseMenu, collapseLayout, switchLang, chooseThemeColor, routerList };

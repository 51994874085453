import { Spin } from "antd";
import React from "react";
interface LoadingType {
  title?: string;
}

const Loading = (props: LoadingType) => {
  const { title } = props;

  return (
    <div className="left-0 top-0 right-0 bottom-0 overflow-hidden fixed z-[10000] flex justify-center items-center bg-cBlack/0 flex-col">
      {/* fullscreen */}
      <Spin size="small" spinning />
      {title && (
        <div className="mt-[10px] text-[14px] text-primary">{title}</div>
      )}
    </div>
  );
};

export default Loading;

import React, { useState } from "react";
import { TreeSelect, Checkbox } from "antd";
import { BoxConfig, extarPropsType } from "../getInput";
import { isFunction } from "../../../utils";
import { t } from "i18next";
import { link } from "fs";

export const BasicTreeSelect = (
  item: BoxConfig,
  { inputDefaultStyle }: extarPropsType
) => {
  const extarStyles = item?.inputOptions?.style || {};
  const [linkAge, setLinkAge] = useState(true);
  const { style, ...inputOptions } = item?.inputOptions;
  return (
    <TreeSelect
      showSearch
      style={{
        width: "100%",
        ...inputDefaultStyle,
        ...extarStyles,
      }}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder={t("comChoose")}
      allowClear
      treeData={item.treeData}
      filterTreeNode={(inputValue: string, treeNode: { title: string }) => {
        const value = (inputValue || "").trim();
        return (treeNode?.title || "")?.includes(value);
      }}
      {...inputOptions}
      treeCheckStrictly={!linkAge}
      onChange={(...prams) => {
        if (isFunction(item.inputOptions?.onChange)) {
          item.inputOptions.onChange(...prams);
        }
      }}
      dropdownRender={(menu) =>
        item.inputOptions.isNeedLinkage ? (
          <>
            <Checkbox
              checked={linkAge}
              onChange={(e) => {
                setLinkAge(!linkAge);
              }}
              className="ml-2"
            >
              {t("comLinkage")}
            </Checkbox>
            {menu}
          </>
        ) : (
          menu
        )
      }
    />
  );
};

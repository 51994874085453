import React from "react";
import { InputNumber } from "antd";
import { BoxConfig, extarPropsType } from "../getInput";
import { isFunction } from "../../../utils";
import { t } from "i18next";

export const BasicInputNumber = (
  item: BoxConfig,
  { inputDefaultStyle, name }: extarPropsType
) => {
  const extarStyles = item?.inputOptions?.style || {};
  return (
    <InputNumber
      // controls={false}
      style={{ width: "100%", ...inputDefaultStyle, ...extarStyles }}
      min={0}
      max={Number.MAX_SAFE_INTEGER}
      precision={2}
      placeholder={t("comEnter")}
      {...item.inputOptions}
      onChange={(...prams) => {
        if (isFunction(item.inputOptions?.onChange))
          item.inputOptions.onChange(...prams);
      }}
    />
  );
};

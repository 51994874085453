import { Alert } from "antd";
import clsx from "clsx";
import { InfoSvg } from "../../svg/InfoSvg";
import CloseSvg from "../../svg/closeSvg";

type AlertType = "success" | "info" | "warning" | "error";
const colorType: { [key: string]: string } = {
  warning: "#FDAD4F",
};
const bgColor: { [key: string]: string } = {
  warning: "bg-bgWarn",
};

const iconSvg: { [key: string]: JSX.Element } = {
  warning: <InfoSvg />,
};

export const AlertWrapper = ({
  text,
  type = "warning",
  className = "",
  showAlert = true,
  onClose = () => {},
  ...props
}: {
  // 文本内容
  text: string;
  // 类型
  type?: AlertType;
  // 自定义类名
  className?: string;
  // 是否显示警告
  showAlert: boolean;
  // 关闭回调函数
  onClose?: () => void;
  // 其他属性
  [key: string]: any;
}): JSX.Element => {
  const color = colorType[type];
  const icon = iconSvg[type];
  console.log("bgColor[type]", bgColor[type]);
  if (!showAlert) return <></>;
  return (
    <div className=" px-6 mb-[1.125rem]">
      <Alert
        className={clsx(
          `text-[${color}] !px-3 !py-[0.875rem]`,
          type === "warning" && "!bg-bgWarn"
        )}
        message={text || "Warning text"}
        banner
        closable={{
          closeIcon: <CloseSvg color={color} />,
        }}
        onClose={onClose}
        icon={<div>{icon}</div>}
        {...props}
      />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { notExist } from "../../utils";
import clsx from "clsx";
import { useGetColor } from "../../utils/hooks";
import isFunction from "lodash/isFunction";

export type TabsItemType = {
  key: string | number | undefined;
  label: string | React.ReactNode;
  children?: React.ReactNode;
  imgPath?: (value: string) => React.ReactNode;
};

interface TabType {
  tabList?: TabsItemType[] | undefined;
  onChange?: (key: any, item: any) => void;
  tabKey?: string;
  iconClass?: string;
  tabClass?: string;
}

/**
 * @description tabs表头页签切换组件
 * @param {TabsItemType[]} tabList
 * @param {Function} onChange 改变页签回调
 * @param {tabKey} tabKey 默认页签
 */

const TabWrapper = (props: TabType) => {
  const { tabList = [], onChange, tabKey, tabClass } = props;
  const [activeKey, setActiveKey] = useState(tabList[0]?.key);
  const custom = useGetColor();
  const handleOnClick = (currentKey: any, item: any) => {
    setActiveKey(currentKey);
    onChange?.(currentKey, item);
  };
  useEffect(() => {
    if (notExist(tabKey)) return;
    setActiveKey(tabKey);
  }, [tabKey]);
  return (
    <div id="tabListCard" className="flex">
      {tabList &&
        tabList.length > 0 &&
        tabList?.map((item) => {
          return (
            <div
              onClick={() => {
                handleOnClick(item.key, item);
              }}
              key={item?.key}
              className={clsx(
                "flex justify-center items-center px-6 py-3 rounded-t-xl  cursor-pointer relative -mr-[8px]",
                tabClass ? tabClass : "gap-[0.625rem]"
              )}
            >
              <div
                className={clsx(
                  "tabDiv w-[97%] h-[91%] absolute rounded-t-lg border border-solid border-b-0 ",
                  `bg-gradient-to-b from-modulesBackground to-background`
                )}
              ></div>
              <div className="flex justify-center items-center">
                <div className={clsx("z-10 mr-2 mt-[2px]", "h-5")}>
                  {isFunction(item?.imgPath)
                    ? item.imgPath(
                        item?.key === (activeKey ?? 1)
                          ? "var(--color-primary)"
                          : "var(--color-textAssist)"
                      )
                    : null}
                </div>
                <div
                  className={clsx(
                    "text z-10",
                    item?.key === (activeKey ?? 1)
                      ? "text-primary"
                      : "text-cMinorTxt"
                  )}
                >
                  {item.label}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TabWrapper;

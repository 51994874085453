import { useEffect, useState } from "react";

let currentTheme: "dark" | "light" =
  (window?.localStorage.getItem("currentTheme") as "dark" | "light") || "dark";
export const useTheme = () => {
  const [theme, setTheme] = useState<"dark" | "light">(currentTheme);
  console.log("theme", theme);
  useEffect(() => {
    window.addEventListener("localStorageChange", (e: any) => {
      setTheme(e.detail.value);
      currentTheme = e.detail.value;
    });
    currentTheme =
      (window?.localStorage.getItem("currentTheme") as "dark" | "light") ||
      "dark";
    setTheme(currentTheme);
  }, []);
  return { theme };
};

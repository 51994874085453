import zhCN from "antd/locale/zh_CN";
import enUS from "antd/locale/en_US";
import fr_FR from "antd/locale/fr_FR";
import de_DE from "antd/locale/de_DE";
import th_TH from "antd/locale/th_TH";

import { getCurrentLanguage } from ".";

export function formatLangType(key: string) {
  // const currentKey =  returnHadDefineLanguageType(defaultLanguage)
  let currentLanguage = getCurrentLanguage(key);
  switch (currentLanguage) {
    case "zh-CN":
      return zhCN;
    case "en-US":
      return enUS;
    case "fr-FR":
      return fr_FR;
    case "de-DE":
      return de_DE;
    case "th-TH":
      return th_TH;
    default:
      return enUS;
  }
}

import { Radio } from "antd";
import { isFunction, mustArray } from "../../../utils";
import { BoxConfig } from "../getInput";

export const BasicRadio = (item: BoxConfig) => {
  const { radioData } = item;
  return (
    <Radio.Group
      {...item.inputOptions}
      onChange={(...prams) => {
        if (isFunction(item.inputOptions?.onChange))
          item.inputOptions.onChange(...prams);
      }}
    >
      {mustArray(radioData).map((radioItem, i) => {
        return (
          <Radio
            id={radioItem.value}
            disabled={radioItem?.disabled}
            value={radioItem.value}
          >
            {radioItem.label}
          </Radio>
        );
      })}
    </Radio.Group>
  );
};

import React from "react";

const DateSvg = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.53025 10.598H13.028M17.6008 6.62164H2.39844M13.3779 1.5542V4.08792M6.62131 1.5542V4.08792M5.53025 13.7156H10.5977M6.45239 18.4457H13.5468C14.2416 18.4457 15.228 18.4248 16.0538 18.4036C16.5929 18.3897 16.8624 18.3827 17.0707 18.2718C17.2532 18.1747 17.4029 18.0211 17.4953 17.8362C17.6008 17.6251 17.6008 17.3538 17.6008 16.8111C17.6008 16.0198 17.6008 15.081 17.6008 14.3917V7.2973C17.6008 6.55583 17.6008 5.48238 17.6008 4.62632C17.6008 4.26986 17.6008 4.09162 17.5558 3.94673C17.4581 3.63226 17.2119 3.38603 16.8974 3.28835C16.7525 3.24335 16.5741 3.24335 16.2173 3.24335V3.24335C16.2173 3.24335 14.9658 3.24335 13.5468 3.24335H6.45239C5.03337 3.24335 3.78187 3.24335 3.78187 3.24335V3.24335C3.4251 3.24335 3.24671 3.24335 3.10182 3.28835C2.78735 3.38603 2.54112 3.63226 2.44344 3.94673C2.39844 4.09162 2.39844 4.26986 2.39844 4.62632C2.39844 5.48238 2.39844 6.55583 2.39844 7.2973V16.8457C2.39844 17.4057 2.39844 17.6858 2.50743 17.8997C2.6033 18.0878 2.75628 18.2408 2.94445 18.3367C3.15836 18.4457 3.43838 18.4457 3.99844 18.4457C4.81642 18.4457 5.63441 18.4457 6.45239 18.4457Z"
        stroke={props.color || "#6C6E73"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DateSvg;

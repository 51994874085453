import React from "react";
import clsx from "clsx";
import { isExist } from "../../utils";
interface focusTextProps {
  text: any;
  textSize?: string;
  fontWeight?: string;
  textColor?: string;
  className?: string;
  color?: string;
  children?: any;
}
export const TEXT_SIZE: { [key: string]: string } = {
  TEXT_48: "text-[3rem]",
  TEXT_4XL: "text-4xl", //36
  TEXT_3XL: "text-[2rem]",
  XXS: "text-[0.625rem]", //10
  XS: "text-xs", // 12
  XL: "text-[1.25rem]", // 20
  SM: "text-[14px]", //14
  BASE: "text-base", //16
  TOW_XL: "text-2xl", //24
  LG: "text-lg", //18
};
export const FONT_WEIGHT: { [key: string]: string } = {
  BOLD: "font-bold", //700
  NORMAL: "font-normal", //400
  MEDIUM: "font-medium", // 500
  SEMIBOLD: "font-semibold", //600
};

// 文本色，重点文本颜色 #18191A
export const FocusText = ({
  text,
  textSize = TEXT_SIZE.SM,
  fontWeight = FONT_WEIGHT.NORMAL,
  className,
  children,
  color = "",
}: focusTextProps) => {
  return (
    <span
      style={{ color }}
      className={clsx("text-cMainTxt", textSize, fontWeight, className)}
    >
      {children}
      {text}
    </span>
  );
};

// 次要文字颜色 #515559
export const MinorText = ({
  text,
  textColor = "text-cMinorTxt",
  textSize = TEXT_SIZE.SM,
  fontWeight = FONT_WEIGHT.NORMAL,
  className,
}: focusTextProps) => {
  return (
    <span
      className={clsx(
        `${isExist(textColor) ? textColor : "text-cMinorTxt"}`,
        textSize,
        fontWeight,
        className
      )}
    >
      {text}
    </span>
  );
};
// 索引暗文字
export const AuxiliaryText = ({
  text,
  textColor = "text-cInfoTxt",
  textSize = TEXT_SIZE.SM,
  fontWeight = FONT_WEIGHT.NORMAL,
  className,
}: focusTextProps) => {
  return (
    <span
      className={clsx(
        `${isExist(textColor) ? textColor : "text-cInfoTxt"}`,
        textSize,
        fontWeight,
        className
      )}
    >
      {text}
    </span>
  );
};
// 正文 cAssistTxt #6C6E73
export const BodyText = ({
  text,
  textColor = "text-cAssistTxt",
  textSize = TEXT_SIZE.SM,
  fontWeight = FONT_WEIGHT.NORMAL,
  className,
}: focusTextProps) => {
  return (
    <span
      className={clsx(
        `${isExist(textColor) ? textColor : "text-cAssistTxt"}`,
        textSize,
        fontWeight,
        className
      )}
    >
      {text}
    </span>
  );
};

import { Dispatch, useMemo, useReducer, useState } from "react";
import { theme } from "antd";
import { CustomTokenType } from "../theme";

export function useSTYReducer<T>(
  initStateValues: { [key: string]: any },
  initFn?: any
): [T, Dispatch<T | { [key: string]: any }>] {
  const [dao, hit] = useReducer(
    (...x) => Object.assign({}, ...x),
    initStateValues,
    initFn
  );
  return [dao, hit];
}

export function useGetColor() {
  const { useToken } = theme;
  const {
    token: { Custom = {} },
  } = (useToken() as any) ?? {};
  return Custom as CustomTokenType;
}

type TMethod<T> = (value: T, ...args: any[]) => any;
export function useMethods<T, M>(
  initialValue: T,
  methods: { [name: string]: TMethod<T> }
): [T, M] {
  const [value, setValue] = useState(initialValue);
  const boundMethods = useMemo(
    () =>
      Object.entries(methods).reduce(
        (methods: { [name: string]: TMethod<T> }, [name, fn]) => {
          methods[name] = async (...args: any[]) => {
            await setValue((value: T) => fn(value, ...args));
          };
          return methods;
        },
        {}
      ),
    [methods]
  );
  return [value, boundMethods as M];
}

import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
// import NProgress from "../config/nprogress";
import { localGetString, localRemove, judgeLanguage } from ".";
import { BASE_PREFIX, CHANGE_LANG, USER_TOKEN } from "../config";
import { message } from "antd";
import i18n from "i18next";
import debounce from "lodash/debounce";
import dayjs from "dayjs";

interface BlobWithFileName {
  data: Blob;
  fileName: string;
}
interface Result {
  success: boolean;
  code: number;
  msg: string;
}

interface ResultData<T> extends Result {
  data?: T;
  list?: T;
}

const config = {
  baseURL:
    process.env.REACT_APP_ENV === "development"
      ? ""
      : window?.env?.REACT_APP_API_URL || "http://172.18.120.92",
  timeout: 20000,
  withCredentials: true,
};

const debounceMsg = debounce(() => {
  message.destroy();
  message.error(i18n.t("sysMaintenance"));
}, 1000);

class RequestHttp {
  service: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.service = axios.create(config);
    // 请求拦截
    this.service.interceptors.request.use(
      (config: any) => {
        // NProgress.start();
        return {
          ...config,
          headers: {
            "Steck-Accept-Language": judgeLanguage(),
            Authorization: localGetString(USER_TOKEN),
            ...config.headers,
          },
        };
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    // 响应拦截
    this.service.interceptors.response.use(
      (response: AxiosResponse) => {
        // NProgress.done();
        const { data = {} } = response;

        // if (data?.code === 499) {
        //   // TODO：会弹两次什么鬼???
        //   debounceMsg();
        //   return Promise.reject(data);
        // }
        if (data?.code === 299) {
          console.log(data, '121232432')
          // TODO：会弹两次什么鬼???
          window.location.href = `${BASE_PREFIX}/systemUpgrade`;
          // return Promise.reject(data);
        }
        if (data?.code === 401) {
          message.destroy();
          message.error(data?.msg);
          localRemove(USER_TOKEN);
          setTimeout(() => {
            window.location.href = `${BASE_PREFIX}/login`;
          }, 2000);
          return Promise.reject(data);
        }

        if (
          response.status === 200 &&
          data?.code !== 200 &&
          !response?.config?.noShowToast
        ) {
          message.destroy();
          message.error(data.msg);
        }
        // 如果是blob,把文件名称返回一下
        if (response?.config?.responseType === "blob") {
          const contentType = response.headers["content-type"];
          let fileName;
          if (response.headers["content-disposition"]) {
            fileName = decodeURI(
              response.headers["content-disposition"].match(/filename=(.*)/)[1]
            );
          } else {
            fileName = dayjs().format("YYYYMMDD");
          }
          const blobData = new Blob([data], { type: contentType });
          const result: BlobWithFileName = { data: blobData, fileName };
          return result;
        }
        return data;
      },
      async (error: AxiosError) => {
        // NProgress.done();
        message.destroy();
        message.error(i18n.t("networkError"));
        return Promise.reject(error);
      }
    );
  }

  // * 常用请求方法封装
  get<T>(url: string, params?: object): Promise<ResultData<T>> {
    return this.service.get(url, { params });
  }
  post<T>(url: string, params?: object, config = {}): Promise<ResultData<T>> {
    return this.service.post(url, params, config);
  }
  put<T>(url: string, params?: object, config = {}): Promise<ResultData<T>> {
    return this.service.put(url, params, config);
  }
  delete<T>(url: string, params?: object): Promise<ResultData<T>> {
    return this.service.delete(url, { params });
  }
}

export default new RequestHttp(config);

import { service } from "common";
export const mutationLogin = (params: User.UserLogin) => {
  return service.post<User.UserLoginResult>("/permission-auth/api/login", params);
};

export const querySideMenuList = (params: User.Menu) => {
  return service.post<User.MenuResult[]>("/permission-account/web/menu/get/children", params);
};

export const mutationActiveAccount = (params: User.AccountActiveType) => {
  return service.post<User.AccountActiveResult>("/permission-account/public/user/activate", params);
};

export const mutationActivateDetail = (params: User.activateDetailType) => {
  return service.post<User.activateDetailResult>("/permission-account/public/activate/detail", params);
};

export const queryUserInfo = (params: any, config?: any) => {
  return service.post<User.UserInfoType>("/permission-account/user/query/detail", params, config);
};

export const queryPolicyTerm = async () => {
  return service.post<User.PolicyTermType>("/permission-account/public/getPolicyTerm");
};

// 获取公司logo

export const getCompanyLogo = async () => {
  return service.post<any>("/permission-account/public/getGroupRegisterInfo");
};
// 获取公司信息
export const getCompanyInfo = async () => {
  return service.post<any>("/permission-account/installer/get/companyInfoTag/V1.0.0");
};

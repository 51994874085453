import React from "react";
import { Checkbox } from "antd";
import { isFunction, mustArray } from "../../../utils";
import { BoxConfig } from "../getInput";

export const BasicCheckbox = (item: BoxConfig) => {
  const { checkData } = item;
  return (
    <Checkbox.Group
      {...item.inputOptions}
      onChange={(...prams) => {
        if (isFunction(item.inputOptions?.onChange))
          item.inputOptions.onChange(...prams);
      }}
    >
      {mustArray(checkData).map((checkItem, i) => {
        return (
          <Checkbox
            id={checkItem.value}
            disabled={checkItem?.disabled}
            value={checkItem.value}
          >
            {checkItem.label}
          </Checkbox>
        );
      })}
    </Checkbox.Group>
  );
};

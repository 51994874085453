import React from "react";
import { ConfigProvider, Spin } from "antd";
import { formatLangType } from "../../utils/langUtils";
interface CustomConfigProviderType {
  children: React.ReactNode;
  theme: any;
  langType: "zh-CN" | "en-US" | string;
  renderEmpty: any;
  loading?: boolean;
}

const CustomConfigProvider = (props: CustomConfigProviderType) => {
  const { children, theme, langType, renderEmpty } = props;
  return (
    <ConfigProvider
      locale={formatLangType(langType)}
      theme={theme}
      renderEmpty={renderEmpty}
    >
      {children}
    </ConfigProvider>
  );
};

export default CustomConfigProvider;

import React from "react";

interface ItemProps {
  children: React.ReactNode;
  className?: string | null;
}

const ListCard = ({ children, className }: ItemProps) => {
  const currentClassName =
    className ?? "bg-modulesBackground  pt-6 rounded-t-[8px]";
  return (
    <div id="listCard" className={`${currentClassName}`}>
      {children}
    </div>
  );
};
export default ListCard;

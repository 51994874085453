import { RouteObject } from "@/routers";

interface MenuOptions {
  path: string;
  title: string;
  icon?: string;
  isFrame?: string;
  close?: boolean;
  children?: MenuOptions[];
}

/**
 * @description 获取localStorage
 * @param {String} key Storage名称
 * @return string
 */
export const localGetString = (key: string) => {
  const value = window?.localStorage?.getItem(key) || "";
  return value;
};

/**
 * @description 获取localStorage
 * @param {String} key Storage名称
 * @return string
 */
export const localGetObj = (key: string) => {
  const value = window?.localStorage?.getItem(key) || "";
  try {
    return JSON.parse(value);
  } catch (error) {
    return undefined;
  }
};

/**
 * @description 存储localStorage
 * @param {String} key Storage名称
 * @param {Any} value Storage值
 * @return void
 */
export const localSet = (key: string, value: string) => {
  window?.localStorage?.setItem(key, value);
};

/**
 * @description 清除localStorage
 * @param {String} key Storage名称
 * @return void
 */
export const localRemove = (key: string) => {
  window?.localStorage?.removeItem(key);
};

/**
 * @description 清除所有localStorage
 * @return void
 */
export const localClearAll = () => {
  window?.localStorage?.clear();
};

/**
 * @description TODO-获取浏览器默认语言-目前只有中文和英文
 * @return string
 */
export const getBrowserLang = (): string => {
  const browserLang = navigator.language;
  let defaultBrowserLang = "";
  if (browserLang.toLowerCase() === "cn" || browserLang.toLowerCase() === "zh" || browserLang.toLowerCase() === "zh-cn") {
    defaultBrowserLang = "zh";
  } else {
    defaultBrowserLang = "en";
  }
  return defaultBrowserLang;
};

/**
 * @description 获取需要展开的 subMenu
 * @param {String} path 当前访问地址
 * @returns array
 */
export const getOpenKeys = (path: string) => {
  let newStr: string = "";
  const newArr: any[] = [];
  const arr = path.split("/").map((i) => "/" + i);
  for (let i = 1; i < arr.length - 1; i++) {
    newStr += arr[i];
    newArr.push(newStr);
  }
  return newArr;
};

/**
 * @description 递归查询对应的路由
 * @param {String} path 当前访问地址
 * @param {Array} routes 路由列表
 * @returns array
 */
export const searchRoute = (path: string, rootRouter: RouteObject[] = []): RouteObject => {
  let result: RouteObject = {};
  for (const item of rootRouter) {
    // todo 优化
    if (item.path && path.endsWith(item.path)) return item;
    if (item.children) {
      const res = searchRoute(path, item.children);
      if (Object.keys(res).length) result = res;
    }
  }
  return result;
};

/**
 * @description 递归当前路由的 所有 关联的路由，生成面包屑导航栏
 * @param {String} path 当前访问地址
 * @param {Array} menuList 菜单列表
 * @returns array
 */
export const getBreadcrumbList = (path: string, menuList: MenuOptions[]) => {
  const tempPath: any[] = [];
  try {
    const getNodePath = (node: MenuOptions) => {
      tempPath.push(node);
      // 找到符合条件的节点，通过throw终止掉递归
      if (node.path === path) {
        throw new Error("GOT IT!");
      }
      if (node.children && node.children.length > 0) {
        for (let i = 0; i < node.children.length; i++) {
          getNodePath(node.children[i]);
        }
        // 当前节点的子节点遍历完依旧没找到，则删除路径中的该节点
        tempPath.pop();
      } else {
        // 找到叶子节点时，删除路径当中的该叶子节点
        tempPath.pop();
      }
    };
    for (let i = 0; i < menuList.length; i++) {
      getNodePath(menuList[i]);
    }
  } catch (e) {
    return tempPath.map((item) => item.title);
  }
};

/**
 * @description 双重递归 找出所有 面包屑 生成对象存到 redux 中，就不用每次都去递归查找了
 * @param {String} menuList 当前菜单列表
 * @returns object
 */
export const findAllBreadcrumb = (menuList: MenuOptions[]): { [key: string]: any } => {
  const handleBreadcrumbList: any = {};
  const loop = (menuItem: MenuOptions) => {
    // 下面判断代码解释 *** !item?.children?.length   ==>   (item.children && item.children.length > 0)
    if (menuItem?.children?.length) menuItem.children.forEach((item) => loop(item));
    else handleBreadcrumbList[menuItem.path] = getBreadcrumbList(menuItem.path, menuList);
  };
  menuList.forEach((item) => loop(item));
  return handleBreadcrumbList;
};

/**
 * @description 使用递归处理路由菜单，生成一维数组，做菜单权限判断
 * @param {Array} menuList 所有菜单列表
 * @param {Array} newArr 菜单的一维数组
 * @return array
 */
export function handleRouter(routerList: MenuOptions[], newArr: string[] = []) {
  routerList.forEach((item: MenuOptions) => {
    typeof item === "object" && item.path && newArr.push(item.path);
    item.children && item.children.length && handleRouter(item.children, newArr);
  });
  return newArr;
}

/**
 * @description 判断数据类型
 * @param {Any} val 需要判断类型的数据
 * @return string
 */
export const getDataType = (val: any) => {
  if (val === null) return "null";
  if (typeof val !== "object") return typeof val;
  else return Object.prototype.toString.call(val).slice(8, -1).toLocaleLowerCase();
};

/**
 * @description 对象数组深克隆
 * @param {Object} obj 源对象
 * @return object
 */
export const deepCopy = <T>(obj: any): T => {
  let newObj: any;
  try {
    newObj = obj.push ? [] : {};
  } catch (error) {
    newObj = {};
  }
  for (const attr in obj) {
    if (typeof obj[attr] === "object") {
      newObj[attr] = deepCopy(obj[attr]);
    } else {
      newObj[attr] = obj[attr];
    }
  }
  return newObj;
};

/**
 * @description 生成随机数
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 * @return number
 */
export function randomNum(min: number, max: number): number {
  const num = Math.floor(Math.random() * (min - max) + max);
  return num;
}

/**
 * @description 生成树结构
 * @param {Number} arr 平铺列表
 * @param {Number} parentId 顶级父级id
 * @return number
 */
export function arrayToTree(arr: any[], parentId: number = 0) {
  // 将所有对象存到 map 中
  const map = arr.reduce((prev, cur) => {
    // 注意：这里是浅拷贝，会修改原数组，当后续再使用原数组会出问题
    prev[cur.id] = cur;
    return prev;
  }, {});
  // 定义返回结果数组
  const result = [];
  // 遍历传入的对象
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    // 当遍历到的对象的 pid 等于 传入的根节点的 id，说明为根节点，直接 push 到数组中
    if (item.parentId === parentId) {
      result.push(item);
      // 退出本次循环
      continue;
    }
    // 如果不是根节点，从 map 中找到 pid 对应的对象，该对象即为当前遍历到的对象的父节点
    const parent = map[item.parentId];
    if (parent) {
      // 给父节点添加 children 属性,并定义为数组
      parent.children = parent.children || [];
      // 在数组中 push 当前子节点
      parent.children.push(item);
    }
  }
  return result;
}

import { initGlobalState, MicroAppStateActions } from "qiankun";

const state = {
  curLang: "",
  renderChildNode: "",
  permsList: [],
};

const actions: MicroAppStateActions = initGlobalState(state);

export { actions };

import React from "react";
import { Cascader } from "antd";
import { BoxConfig, extarPropsType } from "../getInput";
import { isFunction } from "../../../utils";

export const BasicCascader = (
  item: BoxConfig,
  { inputDefaultStyle, name }: extarPropsType
) => {
  const extarStyles = item?.inputOptions?.style || {};
  return (
    <Cascader
      style={{ width: "calc(100%)", ...inputDefaultStyle, ...extarStyles }}
      options={item.cascaderData}
      allowClear
      showSearch
      placeholder={""}
      {...item.inputOptions}
      onChange={(v, p) => {
        if (isFunction(item.inputOptions?.onChange))
          item.inputOptions.onChange(v);
      }}
    />
  );
};

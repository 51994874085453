import React, { useEffect, useState } from "react";
import { Checkbox, List } from "antd";
import { isExist, mustArray, notEmptyArray } from "../../utils";
import MultiLineTextEllipsis from "../MultiLineTextEllipsis";
import clsx from "clsx";
import isFunction from "lodash/isFunction";

interface MenuListItem {
  value: number | string;
  label: string;
  icon?: React.ReactNode;
}

interface BasicMenuBoxProps {
  render: (selectedKeys: any[]) => React.ReactNode;
  children?: React.ReactNode;
  defaultSelectedKey: any[];
  menuList: MenuListItem[];
  header?: React.ReactNode;
  extraMenu?: React.ReactNode;
  checkOnchange: (value: any, item: any) => Boolean;
  icon?: any;
  onChange: (value: any) => void;
  loading: boolean;
  listBoxStyle?: React.CSSProperties;
  isRadio?: boolean; //是否是单选
}

const BasicMenuBox = ({
  render,
  children,
  defaultSelectedKey,
  menuList,
  header,
  extraMenu,
  listBoxStyle,
  checkOnchange,
  icon,
  loading,
  onChange = () => {},
  isRadio = false,
}: BasicMenuBoxProps) => {
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [selectedNodes, setSelectedNodes] = useState<any[]>([]);

  useEffect(() => {
    setSelectedKeys(mustArray(defaultSelectedKey).filter(isExist));
  }, [JSON.stringify(defaultSelectedKey)]);

  const onChangeCheckbox = (value: any, item: any) => {
    let keys = [];
    console.log(item, "ewrew");
    if (isRadio) {
      keys = [value];
    } else {
      if (selectedKeys.includes(value)) {
        keys = selectedKeys.filter((key) => key !== value);
      } else {
        const verify = checkOnchange(value, item);
        if (!verify) return;
        keys = [...selectedKeys, value];
      }
    }

    setSelectedKeys(keys);
    onChange(keys);
  };

  return (
    <div className="w-full h-full">
      <div className="flex h-full">
        <div className=" w-[282px] bg-splitBlockBackground">
          <div>{extraMenu}</div>
          <div className="px-4 pt-4 mb-1">{header}</div>
          <div
            style={{
              height: "calc(100% - 160px)",
              overflowY: "scroll",
              overflowX: "hidden",
              ...listBoxStyle,
            }}
          >
            {notEmptyArray(menuList) && (
              <List
                className=" pl-4 pr-2 mt-1"
                split={false}
                loading={{
                  spinning: loading,
                  size: "small",
                }}
                dataSource={mustArray(menuList)}
                rowKey={(item) => item?.value}
                renderItem={(item) => {
                  const checked = mustArray(selectedKeys).includes(item?.value);
                  const isRadioChecked = checked && isRadio;
                  return (
                    <div
                      className={clsx(
                        "flex gap-2 w-[240px]  px-3 py-[9px] rounded my-1 cursor-pointer hover:bg-selectedColor",
                        isRadioChecked ? "bg-selectedColor" : ""
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        onChangeCheckbox(item?.value, item);
                      }}
                      key={item?.value}
                    >
                      <div className=" flex  w-full items-center gap-2">
                        {!isRadio && (
                          <Checkbox
                            checked={checked}
                            onChange={(e) => {
                              e.preventDefault();
                              onChangeCheckbox(item?.value, item);
                            }}
                          />
                        )}
                        {isExist(icon) ? (
                          <div className=" min-h-[18px] min-w-[18px] flex items-center">
                            {isFunction(icon)
                              ? icon(
                                  isRadioChecked ? "var(--color-primary)" : null
                                )
                              : icon}
                          </div>
                        ) : null}
                        <MultiLineTextEllipsis
                          text={item?.label.split(":")[1]}
                          style={{
                            color: isRadioChecked ? "var(--color-primary)" : "",
                            width: 166,
                          }}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            )}
          </div>
        </div>
        {/* <Divider type={"vertical"} style={{ height: "100%", margin: 0 }} /> */}
        <div className=" flex-1 overflow-scroll">{render?.(selectedKeys)}</div>
      </div>
      {children}
    </div>
  );
};
export default BasicMenuBox;

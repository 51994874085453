import React from "react";
import { Input } from "antd";
import { BoxConfig, extarPropsType } from "../getInput";
import { isFunction } from "../../../utils";

export const BasicPassword = (
  item: BoxConfig,
  { inputDefaultStyle }: extarPropsType
) => {
  const extarStyles = item?.inputOptions?.style || {};
  const { style, ...inputOptions } = item?.inputOptions || {};
  return (
    <Input.Password
      style={{ ...inputDefaultStyle, ...extarStyles }}
      autoComplete="off"
      allowClear
      {...inputOptions}
      onChange={(...prams) => {
        if (isFunction(item.inputOptions?.onChange))
          item.inputOptions.onChange(...prams);
      }}
    />
  );
};

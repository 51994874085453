import React from "react";
const QuestionSvg = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.12065 5.7956C6.27739 5.35005 6.58675 4.97434 6.99395 4.73503C7.40116 4.49571 7.87991 4.40823 8.34544 4.48808C8.81096 4.56793 9.2332 4.80995 9.53737 5.17129C9.84154 5.53263 10.008 5.98995 10.0073 6.46227C10.0073 7.7956 8.00732 8.46227 8.00732 8.46227V9.70006M14.6673 8.00016C14.6673 11.6821 11.6825 14.6668 8.00065 14.6668C4.31875 14.6668 1.33398 11.6821 1.33398 8.00016C1.33398 4.31826 4.31875 1.3335 8.00065 1.3335C11.6825 1.3335 14.6673 4.31826 14.6673 8.00016Z"
        stroke={props.color || "#6C6E73"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="8.0009"
        cy="11.6791"
        r="0.715739"
        fill={props.color || "#6C6E73"}
      />
    </svg>
  );
};

export default QuestionSvg;

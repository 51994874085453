import { CaretDownOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { Fragment, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import clsx from "clsx";

interface CustomTreeProps {
  treeData: any[];
  defalutcheckedKey?: any[];
  onChangeCheck?: (newValue?: any) => void;
  fieldNames?: any;
}

const CustomTreeComponent = (props: CustomTreeProps) => {
  const {
    treeData = [],
    defalutcheckedKey = [],
    fieldNames = {},
    onChangeCheck,
  } = props;

  const { keyCode = "id", label = "label" } = fieldNames;
  const [list, setList] = useState<any[]>([]);
  const [checkedKey, setCheckedKey] = useState<any[]>([]);
  const [halfCheckedKey, setHalfCheckedKey] = useState<any[]>([]);

  useEffect(() => {
    if (defalutcheckedKey && Array.isArray(defalutcheckedKey)) {
      const newData = cloneDeep(defalutcheckedKey);
      setCheckedKey(newData);
    }
  }, [JSON.stringify(defalutcheckedKey)]);

  useEffect(() => {
    if (treeData && Array.isArray(treeData)) {
      const newData = cloneDeep(treeData);
      newData.forEach((node) => {
        traverseTree(node);
      });
      setList(newData);
    }
  }, [treeData]);

  const traverseTree = (item: any) => {
    item.expend = false;
    if (defalutcheckedKey?.includes(item[keyCode])) {
      item.checked = true;
    }
    if (item?.children?.length > 0) {
      item.children?.forEach((child: any) => {
        traverseTree(child);
      });
    }
  };

  const onCheckboxChange = (e: any, record: any) => {
    const { checked } = e.target || {};
    record.checked = checked;
    const newCheckArr = [...checkedKey];
    dealAllCheck(record, checked, newCheckArr);
    setCheckedKey(newCheckArr);
    updateParentChecked(record, checked, newCheckArr);
    const halfArr: any[] = [...halfCheckedKey];
    updateHalfArrChecked(record, halfArr);
    setHalfCheckedKey(halfArr);
    setList([...list]);
    onChangeCheck?.([...new Set([...newCheckArr, ...halfArr])]);
  };

  const updateHalfArrChecked = (record: any, halfArr: any[]) => {
    const findParentItem = findParentNode(list, record[keyCode]) || {};
    if (findParentItem?.[keyCode]) {
      const flag = hasSomeChildCheck(findParentItem);
      if (flag) {
        halfArr.push(findParentItem?.[keyCode]);
        updateHalfArrChecked(findParentItem, halfArr);
      } else {
        const findIdx = halfArr.findIndex(
          (obj) => obj === findParentItem?.[keyCode]
        );
        if (findIdx != -1) {
          halfArr.splice(findIdx, 1);
        }
      }
    }
  };

  const hasSomeChildCheck = (record: any): boolean => {
    if (record?.children?.length > 0) {
      for (const node of record?.children) {
        if (node.checked) {
          return true;
        }
      }
    }
    return false;
  };

  const checkOrNoCheck = (newArr: any[], record: any, checked: boolean) => {
    const findIdx = newArr.findIndex((item) => item === record[keyCode]);
    if (findIdx != -1) {
      if (!checked) {
        newArr.splice(findIdx, 1);
      }
    } else {
      if (checked) {
        newArr.push(record[keyCode]);
      }
    }
  };

  const dealAllCheck = (record: any, isCheck: boolean, newArr: any[]) => {
    checkOrNoCheck(newArr, record, isCheck);
    if (record?.children?.length > 0) {
      record.children?.forEach((node: any) => {
        checkAllChildrenFn(node, isCheck, newArr);
      });
    }
  };

  const checkAllChildrenFn = (item: any, isCheck: boolean, newArr: any[]) => {
    item.checked = isCheck;
    checkOrNoCheck(newArr, item, isCheck);
    if (item?.children?.length > 0) {
      item.children.forEach((child: any) => {
        checkAllChildrenFn(child, isCheck, newArr);
      });
    }
  };

  const updateParentChecked = (
    record: any,
    checked: boolean,
    newArr: any[]
  ) => {
    const findParentItem = findParentNode(list, record[keyCode]) || {};
    if (findParentItem?.[keyCode]) {
      if (!checked) {
        checkOrNoCheck(newArr, findParentItem, false);
        findParentItem.checked = false;
        updateParentChecked(findParentItem, false, newArr);
      } else {
        const isAllChildCheck = allChildHadCheck(findParentItem);
        if (isAllChildCheck) {
          checkOrNoCheck(newArr, findParentItem, true);
          findParentItem.checked = true;
          updateParentChecked(findParentItem, true, newArr);
        }
      }
    }
  };

  const allChildHadCheck = (record: any): boolean => {
    if (record?.children?.length > 0) {
      for (const node of record?.children) {
        if (!node.checked) {
          return false;
        }
        if (node?.children?.length > 0) {
          const foundNodeBool = allChildHadCheck(node.children);
          if (!foundNodeBool) {
            return foundNodeBool;
          }
        }
      }
    }
    return true;
  };

  const findParentNode = (data: any[], nodeId: any): any => {
    for (const node of data) {
      if (node?.children?.length > 0) {
        for (const child of node.children) {
          if (child[keyCode] === nodeId) {
            return node;
          }
          const foundNode = findParentNode(node.children, nodeId);
          if (foundNode?.[keyCode]) {
            return foundNode;
          }
        }
      }
    }
    return {};
  };

  const TreeNode = (nodeProps: any) => {
    const { item = {} } = nodeProps;
    return (
      <div>
        <div className="flex items-center">
          {item?.children?.length > 0 ? (
            <div
              onClick={() => {
                item.expend = !item.expend;
                setList([...list]);
              }}
              className={clsx(
                "flex items-center justify-center cursor-pointer",
                item.expend ? "" : "rotate-[270deg]"
              )}
            >
              <CaretDownOutlined />
            </div>
          ) : (
            <div className="w-[14px] h-[14px]"></div>
          )}
          <div className="flex items-center pl-[4px]">
            <Checkbox
              checked={item.checked}
              onChange={(e) => onCheckboxChange(e, item)}
            >
              {item?.[label] || "--"}
            </Checkbox>
          </div>
        </div>
        {item?.children?.length > 0 && item.expend && (
          <div style={{ paddingLeft: "20px" }}>
            {item.children.map((child: any) => (
              <TreeNode key={child[keyCode]} item={child} />
            ))}
          </div>
        )}
      </div>
    );
  };

  const TreeView = () => {
    return (
      <div>
        {list?.map((item, index) => (
          <TreeNode key={item[keyCode]} item={item} />
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      <TreeView />
    </Fragment>
  );
};

export default CustomTreeComponent;

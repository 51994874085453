import { Component } from "react";
import { withTranslation } from "react-i18next";
import { systemIsAndroidOrIosOrPc } from "common";
import { Button, Form, Input, InputNumber, Row, message } from "antd";
import { deregisterEmail, verifyCode } from "./services";
import _ from "lodash";
import "./index.css";
const TITLE = "Account deletion form";
const TEXT_TITLE_LOGIN_OUT =
  "To delete your personal account on atmozen.You need to provide your email address.We will send a deletion confirmation link to your email address.";
const EMAIL = "developer@atmoce.com";
type StateTy = {
  Countdown: number;
  code: number | null;
  email: string;
};
let timer: NodeJS.Timeout;
class Loginout extends Component<
  {
    t: any;
  },
  StateTy
> {
  constructor(props: any) {
    super(props);
    this.state = {
      Countdown: 0,
      code: null,
      email: "",
    };
  }
  /**
   *@description:检验邮箱
   *@author: 清欢
   *@param:
   *@createTime: 2024-08-15 18:17:45
   */
  validateEmail = (str: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(str).toLowerCase());
  };
  /**
   *@description:获取验证码 定时器
   *@author: 清欢
   *@param:
   *@createTime: 2024-08-13 17:08:58
   */
  getCode = async () => {
    if (!this.state.email) {
      message.error("Please enter your email address");
      return;
    }
    if (!this.validateEmail(this.state.email)) {
      message.error("The email format is incorrect, please re-enter it.");
      return;
    }
    const res = await deregisterEmail({ email: this.state.email });
    if (res.code === 200) {
      message.success("The verification code was obtained successfully, please check it in your email");
      this.setState({ Countdown: 60 });
      timer = setInterval(() => {
        if (this.state.Countdown === 1) {
          clearInterval(timer);
        }
        this.setState({ Countdown: this.state.Countdown - 1 });
      }, 1000);
    }
  };
  componentDidMount(): void {
    localStorage.setItem("currentTheme", "light");
  }
  /**
   *@description:表单数据提交
   *@author: 清欢
   *@param:
   *@createTime: 2024-08-13 17:22:35
   */
  onFinish = async (values: { email: string; code: string }) => {
    const { email, code } = values;
    if (!email) {
      message.error("Please enter your email address.");
      return;
    }
    if (!this.validateEmail(email)) {
      message.error("The email format is incorrect, please re-enter it.");
      return;
    }
    if (!code) {
      message.error("Please enter your code.");
      return;
    }
    try {
      const res = await verifyCode({ email, verifyCode: code });
      if (res.code === 200) {
        this.setState({ email: "", code: null });
        if (res.data.tip) {
          message.error(res.data.tip);
          return;
        }
        message.success("The cancellation application has been submitted, please pay attention to the email reply.");
      }
    } catch (err) {}
  };
  render() {
    return (
      <div id="emailbox" style={{ height: window.innerHeight }}>
        <img src={require("../../assets/war-tips.png")} className="w-12 h-12" />
        <h3 style={{ marginTop: 12, fontFamily: "PingFang SC" }}>{TITLE}</h3>
        <div
          style={{
            width: "100%",
            color: "#515559",
            marginTop: "1%",
            fontSize: "16px",
            lineHeight: "22.4px",
            fontFamily: "PingFang SC",
            maxWidth: systemIsAndroidOrIosOrPc() === "PC" ? "40%" : "94%",
          }}
        >
          {TEXT_TITLE_LOGIN_OUT}
        </div>
        <div className="emailtitle rounded-tr-md rounded-br-md rounded-tl-md rounded-bl-md pl-4 mt-6 mb-6" style={{ width: "90%" }}>
          <img src={require("../../assets/Vector.png")} alt="warning" className="cloud" />
          <span className="font-bold pt-3 pb-3" style={{ fontFamily: "PingFang SC" }}>
            {EMAIL}
          </span>
        </div>
        <div className="align-center" style={{ marginRight: 0, paddingRight: 0, width: "94%" }}>
          <Form name="validateOnly" layout="vertical" autoComplete="off" onFinish={_.throttle(this.onFinish, 3000)}>
            <Form.Item name="email" label={"Email"} rules={[{ required: false }]} labelCol={{ className: "custom-label" }}>
              <Row className="-mt-1">
                <Input
                  placeholder="Enter email"
                  value={this.state.email}
                  style={{ border: "1px solid #EEF2F7", height: "40px" }}
                  onChange={(val) => {
                    this.setState({ email: val.target.value });
                  }}
                />
              </Row>
            </Form.Item>
            <Form.Item name="code" label={"Verification code"} rules={[{ required: false }]} labelCol={{ className: "custom-label" }}>
              <Row className="flex-row -mt-1" style={{ width: "100%" }}>
                <InputNumber
                  value={this.state.code}
                  onChange={(val: any) => {
                    this.setState({ code: val });
                  }}
                  placeholder="Enter confirmation code"
                  style={{ border: "1px solid #EEF2F7", width: "52%", marginRight: "2%", height: "40px", lineHeight: "40px" }}
                />
                <Button
                  disabled={!(this.state.Countdown === 0)}
                  onClick={_.throttle(this.getCode, 3000)}
                  style={{
                    padding: "0 20px",
                    backgroundColor: "#EEF2F7",
                    color: "#3E7EF8",
                    border: "1px solid #EEF2F7",
                    width: "46%",
                    height: "40px",
                  }}
                >
                  {this.state.Countdown === 0 ? "Get verification code" : this.state.Countdown}s
                </Button>
              </Row>
            </Form.Item>
            <Form.Item>
              <Button
                style={{
                  padding: "0 20px",
                  backgroundColor: "#3E7EF8",
                  color: "#fff",
                  border: "1px solid #EEF2F7",
                  width: "100%",
                  marginTop: "20px",
                  height: "40px",
                }}
                htmlType="submit"
              >
                Comfirm
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
const LoginoutPageHtml = withTranslation()(Loginout);
export default LoginoutPageHtml;

import { Select } from "antd";
import type { SelectProps } from "antd";
import { BoxConfig, extarPropsType } from "../getInput";
import { isFunction } from "../../../utils";
import { t } from "i18next";

export const BasicSelect = (
  item: BoxConfig,
  { inputDefaultStyle, name }: extarPropsType
) => {
  const options: SelectProps["options"] = item?.selectData;
  const extarStyles = item?.inputOptions?.style || {};

  return (
    <Select
      style={{
        minWidth: "250px",
        ...inputDefaultStyle,
        ...extarStyles,
      }}
      allowClear
      options={options}
      showSearch={true}
      placeholder={t("comChoose")}
      filterOption={(
        input: string,
        option?: { label: string; value: string }
      ) => {
        const value = (input.toLowerCase() || "").trim();
        return (option?.label ?? "").toLowerCase().includes(value);
      }}
      {...item.inputOptions}
      onChange={(value, record) => {
        if (isFunction(item.inputOptions?.onChange))
          item.inputOptions.onChange(value, record);
      }}
    />
  );
};

import React from "react";
import { Input } from "antd";
import { BoxConfig, extarPropsType } from "../getInput";
import { isFunction } from "../../../utils";
import { t } from "i18next";
import SearchSvg from "../../../svg/searchSvg";
export const BasicInput = (
  item: BoxConfig,
  { inputDefaultStyle }: extarPropsType
) => {
  const extarStyles = item?.inputOptions?.style || {};
  const { style, ...inputOptions } = item?.inputOptions || {};

  return (
    <Input
      style={{ ...inputDefaultStyle, ...extarStyles }}
      autoComplete="off"
      allowClear
      placeholder={t("comEnter")}
      disabled={item.disabled}
      {...inputOptions}
      // prefix={isPre ? <SearchSvg color="var(--color-textAssist)" /> : null}
      onChange={(...prams) => {
        if (isFunction(item.inputOptions?.onChange))
          item.inputOptions.onChange(...prams);
      }}
    />
  );
};

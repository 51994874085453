import { CSSProperties } from "react";
import { Button, Form, FormInstance } from "antd";
import { getInput, radioDataType, selectDataType } from "../QueryBar/getInput";
import { isExist, mustArray, notEmptyArray } from "../../utils";
import React from "react";
import { useTranslation } from "react-i18next";

import QuestionSvg from "../../svg/question";
const inputDefaultStyle = {
  height: "2.25rem",
};

interface FormItemContextProps {
  error?: string[];
  form: FormInstance;
}
export const FormItemContext = React.createContext<FormItemContextProps>({});
export interface IItemProps {
  name: string;
  label?: string | JSX.Element;
  value?: any;
  required?: boolean;
  requiredName?: string;
  options?: any;
  disabled?: boolean;
  inputType?: string;
  id?: string;
  key?: string | number;
  inputOptions?: any;
  radioData?: radioDataType[];
  span?: number;
  selectData?: selectDataType[];
  treeData?: any;
  tooltip?: string | React.ReactNode;
  hide?: boolean; //是否隐藏此表单
  style?: any;
  formItemStyle?: CSSProperties;
  useRequiredRules?: boolean; //使用默认的必填校验规则  默认值为true;因为有的场景是required为true但不要这个rules
}

export interface FormWrapperType {
  form: FormInstance<any>;
  formItems: IItemProps[];
  name?: string;
  className?: string;
  formStyle?: CSSProperties;
  error?: string[];
  layout?: "horizontal" | "vertical" | "vertical";
  key?: string;
  scrollToFirstError?: boolean;
  labelCol?: any;
  variant?: "outline" | "filled" | "borderless";
  submitEnter?: boolean;
  onFinish?: (values: any) => void;
  btnContent?: {
    btnText?: string;
    btnDisable?: boolean;
    hideBtn?: boolean;
    btnLoading?: boolean;
  };
}

const FormItem = ({ item }: any) => {
  const {
    name,
    label,
    required = false,
    requiredName,
    options,
    disabled,
    tooltip,
    value,
    useRequiredRules = true,
    formItemStyle = {},
    ...props
  }: IItemProps = item;
  const { t } = useTranslation();
  const help = t("comRulesRequired", {
    name: isExist(requiredName) ? requiredName : label,
  });
  const span = item?.span || 1;
  let resultRules = required ? [{ required: true, message: help }] : [];
  const { rules = [], ...extraOptions } = options || {};
  if (notEmptyArray(rules)) {
    if (useRequiredRules) {
      resultRules = resultRules.concat(rules);
    } else {
      resultRules = rules;
    }
  }
  return (
    <div
      style={{
        gridColumnEnd: `span ${span}`,
        cursor: disabled ? "not-allowed" : "default",
        ...formItemStyle,
      }}
    >
      <Form.Item
        key={name}
        name={name}
        tooltip={
          isExist(tooltip)
            ? {
                icon: (
                  <div
                    style={{
                      display: "flex",
                      paddingLeft: 4,
                      cursor: "default",
                    }}
                  >
                    <QuestionSvg color="var(--color-textAssist)" />
                  </div>
                ),
                title: tooltip,
              }
            : false
        }
        label={label}
        initialValue={value}
        rules={resultRules}
        {...extraOptions}
      >
        {getInput({ ...props, value, disabled }, { inputDefaultStyle, name })}
      </Form.Item>
    </div>
  );
};

const FormWrapper = ({
  form,
  formItems,
  name,
  formStyle,
  variant,
  submitEnter,
  btnContent,
  ...props
}: FormWrapperType) => {
  const contextValue = {
    error: props?.error,
    form: form,
  };
  const submitEnterBtn = submitEnter
    ? [
        <Form.Item
          key="submit"
          className=" m-0"
          hidden={btnContent?.hideBtn ?? true}
        >
          <Button
            hidden={btnContent?.hideBtn ?? true}
            disabled={btnContent?.btnDisable}
            loading={btnContent?.btnLoading}
            type="primary"
            htmlType="submit"
            className=" w-full h-10"
            // onClick={(e) => {
            //   e.preventDefault();
            //   const values = form.getFieldsValue() || {};
            //   props?.onFinish && props?.onFinish(values);
            // }}
          >
            {btnContent?.btnText || "提交"}
          </Button>
        </Form.Item>,
      ]
    : [];

  return (
    <div>
      <Form
        form={form}
        name={name || "FormWrapper"}
        onFinish={() => {
          const values = form.getFieldsValue() || {};
          props?.onFinish && props?.onFinish(values);
        }}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, calc((100% - 2 * 10px) / 2)",
          gridAutoRows: "minmax(24px, auto)",
          gridColumnGap: "1rem",
          ...formStyle,
        }}
        {...props}
      >
        <FormItemContext.Provider value={contextValue}>
          {[
            ...mustArray(formItems).map((item, index) => {
              if (item.hide) {
                return null;
              }
              return (
                <FormItem
                  key={`${item?.name || item?.key || name}${index + 1}`}
                  item={item}
                />
              );
            }),
            ...submitEnterBtn,
          ]}
        </FormItemContext.Provider>
      </Form>
    </div>
  );
};
export default FormWrapper;

import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "@/locales";
import "./global.css";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    // dsn: "http://eef9b4cab7961e8fa2c6db28e68ec24c@cn-monitor.atmocecloud.com:9600/3",
    // dsn: "http://eef9b4cab7961e8fa2c6db28e68ec24c@172.18.120.125:9000/3",
    // dsn: "https://219bf1f245ae42cbe5a4cd4b7aee27f3@monitor.atmocecloud.com/7",
    dsn: window?.env?.REACT_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      // 会话重播功能
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0, //  故障核定
    tracePropagationTargets: [],
    replaysSessionSampleRate: 0.1, // 采样率
    replaysOnErrorSampleRate: 1.0, // error采样
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);

import { Component } from "react";
import { withTranslation } from "react-i18next";
import "./index.css";
import { systemIsAndroidOrIosOrPc } from "common";
import { appUrl } from "./services";
type StateTy = {
  title: boolean;
  version: string;
  model: string;
  releaseType: string;
  resp: {
    android: string;
    appStore: string;
    googlePlay: string;
  };
};
class SharePage extends Component<
  {
    t: any;
  },
  StateTy
> {
  constructor(props: any) {
    super(props);
    this.state = {
      title: true,
      version: "",
      model: "",
      releaseType: "",
      resp: {
        android: "",
        appStore: "",
        googlePlay: "",
      },
    };
  }
  async autoGetAddress(terminalType: string) {
    const res = await appUrl({ terminalType });
    return res.data;
  }
  async autoClick() {
    const [, urlParams] = window.location.href.split("?");
    switch (systemIsAndroidOrIosOrPc()) {
      case "iOS": {
        const resp = await this.autoGetAddress("ios");
        if (resp) {
          this.setState({ version: this.props.t("versionApple"), model: "IOS", resp });
        }
        break;
      }
      case "Android": {
        const resp = await this.autoGetAddress("android");
        if (resp) {
          let releaseType = "in"; //应用内安卓（应用商店）
          if (urlParams === "prodsh") {
            releaseType = "out"; //Apk等等
          }
          this.setState({ version: this.props.t("versionAndroid"), model: "Android", resp, releaseType });
        }
        break;
      }
      default: {
        this.setState({
          title: false,
        });
      }
    }
  }
  componentDidMount(): void {
    this.autoClick();
  }
  render() {
    return (
      <div className="bck" style={{ height: window.innerHeight }}>
        <div className="Atmoce"></div>
        <div className="box">
          <div
            style={{
              fontSize: 20,
              fontWeight: 600,
              textAlign: "center",
              color: "#18191A",
            }}
          >
            {this.state.title ? `Atmozen ${this.props.t("download")}` : this.props.t("scandownload")}
            <br />
            <p className="des">Clean Energy For Better Life</p>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: 50 }}>
          {this.state.model === "IOS" && this.state.resp.appStore && (
            <>
              <div className="modelType">IOS</div>
              <>
                <div className="secBox">
                  <div className="downtype" />
                  <div style={{ display: "inline-flex", flexDirection: "column" }}>
                    <div style={{ fontSize: 18, color: "#18191A" }}>App Store</div>
                    <div style={{ fontSize: 14, color: "#84878C" }}>{this.state.version}</div>
                  </div>
                  <div
                    onClick={() => {
                      window.open(this.state.resp.appStore);
                    }}
                    className="btns"
                  >
                    {this.props.t("open")}
                  </div>
                </div>
                <div className="deviderLine" />
              </>
            </>
          )}
          {this.state.model === "Android" && (this.state.resp.googlePlay || this.state.resp.android) && (
            <>
              <div className="modelType">Android</div>
              {this.state.releaseType === "in" && (
                <>
                  <div className="secBox">
                    <div className="downtype1" />
                    <div style={{ display: "inline-flex", flexDirection: "column" }}>
                      <div style={{ fontSize: 18, color: "#18191A" }}>Google Play</div>
                      <div style={{ fontSize: 14, color: "#84878C" }}>{this.state.version}</div>
                    </div>
                    <div
                      onClick={() => {
                        window.open(this.state.resp.googlePlay);
                      }}
                      className="btns"
                    >
                      {this.props.t("open")}
                    </div>
                  </div>
                  <div className="deviderLine" />
                </>
              )}
              {this.state.releaseType === "out" && this.state.resp.android && (
                <div className="secBox">
                  <div className="downtype2" />
                  <div style={{ display: "inline-flex", flexDirection: "column" }}>
                    <div style={{ fontSize: 18, color: "#18191A" }}>{this.props.t("browser")}</div>
                    <div style={{ fontSize: 14, color: "#84878C" }}>{this.state.version}</div>
                  </div>
                  <div
                    className="btns"
                    onClick={() => {
                      window.location.href = this.state.resp.android;
                    }}
                  >
                    {this.props.t("open")}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
const SharePageHtml = withTranslation()(SharePage);
export default SharePageHtml;

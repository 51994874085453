import React from "react";
import { Image } from "antd";

interface BasicImageProps {
  items: string[];
  src?: string;
  style?: any;
}

const BasicImage = ({ items, style, ...props }: BasicImageProps) => (
  <Image.PreviewGroup>
    {items.map((src, index) => {
      return (
        <Image
          key={`${index + 1}`}
          src={src || items[0]}
          alt=""
          style={{ height: 30, ...style }}
          {...props}
        />
      );
    })}
  </Image.PreviewGroup>
);

export default BasicImage;

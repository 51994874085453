import { BoxConfig, extarPropsType } from "../getInput";
import { DatePicker } from "antd";
import { disableFutureDates, isFunction } from "../../../utils";
import { useTranslation } from "react-i18next";
import {
  DateType,
  getFormat,
  getTimeFormat,
  TimeType,
} from "../../../utils/DateUtils";
const { RangePicker } = DatePicker;
export const BasicDateRangePicker = (
  item: BoxConfig,
  { inputDefaultStyle }: extarPropsType
) => {
  const { t } = useTranslation();
  const extarStyles = item?.inputOptions?.style || {};
  const { style, ...inputOptions } = item?.inputOptions || {};
  const showTime = inputOptions?.showTime || false;

  const timeFormat = showTime ? getTimeFormat(TimeType.SS, false) : "";
  const dateFormat = `${getFormat(DateType.DAY)} ${timeFormat}`;

  return (
    <RangePicker
      style={{ width: "100%", ...inputDefaultStyle, ...extarStyles }}
      placeholder={[t("comStartDate"), t("comEndDate")]}
      format={dateFormat}
      disabledDate={
        !item.disableDate
          ? (current) => {
              return disableFutureDates(current);
            }
          : false
      }
      {...inputOptions}
      onChange={(...prams) => {
        if (isFunction(item.inputOptions?.onChange))
          item.inputOptions.onChange(...prams);
      }}
    />
  );
};

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Informational,
  WarningSvg,
  ErrorSvg,
  ClearAlert,
} from "../../svg/tipsSvg";
import { Alert } from "antd";
import { FocusText, TEXT_SIZE } from "../BasicText";

// 确保其他SVG组件也被导入

/**
 * BasicAlert 组件展示不同类型的警告信息。
 *
 * @param {string} text - 警告信息的文本内容。
 * @param {AlertType} type - 警告的类型，默认为'warning'。可选值有："info" | "success" | "warning" | "error"。
 * @param {string} className - 额外的类名，用于自定义样式。
 * @returns {JSX.Element} 返回渲染后的警告信息组件。
 */
type AlertIcon = React.ReactElement | string;
type AlertColor = {
  bg: string;
  color: string;
  iconColor: string;
};

type AlertType = "info" | "success" | "warning" | "error" | "normal";

// 没写完，没时间了，后面完善
const alertConfigs: { [key: string]: AlertColor } = {
  // warning: ["text-[#FDAD4F]", <InfoSvg />],
  normal: {
    bg: "var(--color-blueBg)",
    color: "cBlue",
    iconColor: "var(--color-blueColor)",
  },
  warning: {
    bg: "var(--color-warnBg)",
    color: "cWarn",
    iconColor: "var(--color-warnColor)",
  },
  error: {
    bg: "var(--color-errorBg)",
    color: "cError",
    iconColor: "var(--color-errorColor)",
  },
};

const tipSvg: { [key: string]: React.ReactNode } = {
  normal: <Informational />,
  warning: <WarningSvg />,
  error: <ErrorSvg />,
};

const BasicAlert = ({
  text,
  keyValue,
  type = "normal",
  isClose = true,
  style = {},
  children = null,
}: {
  text: string;
  keyValue?: string;
  isClose?: boolean;
  type?: AlertType;
  style?: Object;
  children?: React.ReactNode;
}): JSX.Element => {
  const { bg } = alertConfigs[type as AlertType];
  const [hideInfo, setHideInfo] = useState<string>("1");
  useEffect(() => {
    setHideInfo(localStorage.getItem(keyValue || "") || "");
  }, []);
  return (
    <>
      {!hideInfo && (
        <Alert
          style={{
            background: bg,
            marginBottom: 20,
            ...style,
          }}
          className={`flex items-center justify-between px-[12px] py-[8px] bg-${bg}`}
          description={
            <div className="flex justify-between">
              <div className="flex gap-2">
                <div className=" flex h-[24px] items-center">
                  {tipSvg[type]}
                </div>
                {children ? (
                  children
                ) : (
                  <FocusText
                    textSize={TEXT_SIZE.SM}
                    className="leading-[24px]"
                    text={text}
                  />
                )}
              </div>
              {isClose && (
                <div
                  className="flex items-center h-[24px] cursor-pointer pl-4"
                  onClick={() => {
                    keyValue && localStorage.setItem(keyValue, "1");
                    setHideInfo("1");
                  }}
                >
                  <ClearAlert color={"var(--color-textMinor)"} />
                </div>
              )}
            </div>
          }
          type="warning" // 这个虽然写了，但是没用，因为样式重写了
          showIcon={false}
          closable={false}
        />
      )}
    </>
  );
};

export default BasicAlert;

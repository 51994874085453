import React from "react";
export interface HighlightTextProps {
  style?: React.CSSProperties;
  highlightTextStyle?: React.CSSProperties;
  text: string; //被%%...%%包裹的文字将被高亮
  clickableIndexs?: number[]; // 传入可点击高亮内容的index， 从0开始
  onClickIndex?: (index: number) => void; // 传入点击方法，通过index判断点击位置
}
/**
 * 高亮文本
 * @param props
 * @returns
 */
const HighlightText = (props: HighlightTextProps) => {
  const {
    text,
    highlightTextStyle,
    style,
    onClickIndex,
    clickableIndexs = [],
  } = props;
  const list = text.split(/(%%)/g);
  let highlight = false;
  let highlightIndex = -1;
  return (
    <div style={style}>
      {list.map((e, index) => {
        if (highlight === false && e === "%%") {
          highlightIndex += 1;
          highlight = true;
          return "";
        }
        if (highlight === true && e === "%%") {
          highlight = false;
          return "";
        }
        if (highlight) {
          if (clickableIndexs.includes(highlightIndex)) {
            let tempIndex = highlightIndex;
            return (
              <span
                key={e + index}
                onClick={() => onClickIndex && onClickIndex(tempIndex)}
                style={{
                  cursor: `${onClickIndex ? "pointer" : "default"}`,
                  ...highlightTextStyle,
                }}
              >
                {e}
              </span>
            );
          } else {
            return (
              <span key={e + index} style={highlightTextStyle}>
                {e}
              </span>
            );
          }
        }
        return <span key={e + index}>{e}</span>;
      })}
    </div>
  );
};
export default HighlightText;

import { message } from "antd";
import React from "react";

export function messageError(
  content?: string,
  duration: undefined | number = undefined,
  options?: any
): void {
  message.destroy();
  message.open({
    type: "error",
    content: content,
  });
}
export function messageSuccess(
  content: React.ReactNode,
  duration: number | undefined = undefined,
  isLoading?: boolean
) {
  message.destroy();
  message.open({
    type: "success",
    content: content,
  });
}

import { useEffect, useState, forwardRef } from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
// register theme object

const axisCommon = function (type: string) {
  return {
    axisLine: {
      lineStyle: {
        color: type === "dark" ? "#2A2e3b" : "#E2E8F0",
      },
    },
    splitLine: {
      lineStyle: {
        color: type === "dark" ? "#2A2e3b" : "#E2E8F0",
      },
    },
  };
};

interface CustomEchartsType {
  option: {};
  key?: string;
  style?: {};
  theme?: string;
  notMerge?: boolean;
  callBack?: any;
  type?: string;
  backgroundColor?: string;
}

const CustomEcharts = forwardRef((props: CustomEchartsType, ref?: any) => {
  const { backgroundColor } = props;
  const [theme, setTheme] = useState("");
  const [loading, setLoding] = useState(false);
  const getInit = (val: string) => {
    setTheme(val);

    echarts &&
      echarts.registerTheme("dark", {
        darkMode: true,
        backgroundColor: backgroundColor || "#202329",
        textStyle: {
          color: "#6C6E73",
        },
        borderColor: "#fff",
        valueAxis: axisCommon("dark"),
        categoryAxis: axisCommon("dark"),
        timeAxis: axisCommon("dark"),
        logAxis: axisCommon("dark"),
        tooltip: {
          borderWidth: 0, // 取消边框
          backgroundColor: "#262930",
          borderColor: "#262930",
        },
      });

    echarts &&
      echarts.registerTheme("light", {
        backgroundColor: backgroundColor || "#fff",
        textStyle: {
          color: "#9DA6AD",
        },
        darkMode: true,
        valueAxis: axisCommon("light"),
        categoryAxis: axisCommon("light"),
        tooltip: {
          backgroundColor: "#fff",
          borderColor: "#F5F5F7",
          textStyle: {
            color: "#262930",
          },
        },
      });
    setLoding(true);
  };

  useEffect(() => {
    const currentTheme: "dark" | "light" =
      (window?.localStorage.getItem("currentTheme") as "dark" | "light") ||
      "dark";
    window.addEventListener("localStorageChange", (e: any) => {
      getInit(e.detail.value);
    });
    getInit(currentTheme);
  }, []);

  return (
    <>
      {loading && (
        <ReactEcharts
          ref={ref}
          theme={theme}
          onEvents={props.callBack}
          {...props}
        />
      )}
    </>
  );
});

export default CustomEcharts;

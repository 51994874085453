import React, { Fragment } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import _ from "lodash";

interface FooterButtonProps {
  okText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onSubmit: () => void;
  submitLoading?: boolean;
  showOk?: boolean;
  showCancel?: boolean;
  submitStyle?: string;
  cancelStyle?: string;
  okProps?: any;
  isDebounce?: boolean;
}

const FooterButton = ({
  okText,
  cancelText,
  onCancel,
  onSubmit,
  submitLoading = false,
  showOk = true,
  showCancel = true,
  submitStyle = "",
  cancelStyle = "",
  okProps = {},
  isDebounce = false,
}: FooterButtonProps) => {
  const { t } = useTranslation();
  const btnOkText = okText || t("comSave");
  const btnCancelText = cancelText || t("comCancel");

  const handleSubmit = isDebounce ? _.debounce(onSubmit, 500) : onSubmit;
  return (
    <Fragment>
      {showCancel && (
        <Button
          className={clsx(
            "!px-[1rem] !rounded-[0.25rem] h-[2.25rem]",
            cancelStyle
          )}
          key="back"
          size="middle"
          onClick={onCancel}
        >
          {btnCancelText}
        </Button>
      )}
      {showOk && (
        <Button
          loading={submitLoading}
          className={clsx(
            `!px-[1rem] !rounded-[0.25rem] h-[2.25rem] !text-textDisable`,
            submitStyle
          )}
          style={{
            background: `${okProps.disabled ? "var(--color-disableButtonBg)" : "var(--color-primary)"}`,
            color: `#fff`,
          }}
          key="submit"
          type="primary"
          size="middle"
          onClick={() => handleSubmit()}
          {...okProps}
        >
          {btnOkText}
        </Button>
      )}
    </Fragment>
  );
};
export default FooterButton;

import { Spin } from "antd";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../hooks/useTheme";
import noDataPng from "../../assets/noData.png";
import darkNoDataPng from "../../assets/darkNoData.png";
interface GlobalNoDataType {
  className?: string;
}
const GlobalNoData = (props?: GlobalNoDataType) => {
  const { t } = useTranslation();
  const { className } = props || {};
  const { theme } = useTheme();
  // const getSvg = () => {
  //   const currentTheme: "dark" | "light" =
  //     (window?.localStorage.getItem("currentTheme") as "dark" | "light") ||
  //     "dark";
  //   return currentTheme === "dark" ? <NoDataSvg /> : <NoDataLightSvg />;
  // };
  const getSvg = useMemo(() => {
    return <img src={theme === "dark" ? darkNoDataPng : noDataPng} />;
  }, [theme]);

  return (
    <div style={{ textAlign: "center" }} className={className}>
      {getSvg}
      <p className="text-cAssistTxt">{t("noData")}</p>
    </div>
  );
};

export default GlobalNoData;
